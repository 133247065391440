// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap-icons/font/bootstrap-icons.css'

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks"
//import $ from 'jquery';
import 'jquery';
import 'bootstrap';
import "stylesheets/application"
import Planpolitik from "../planpolitik.js"


window.Rails = Rails;
Rails.start();
Turbolinks.start();
$(() => {
	Planpolitik.start()
})