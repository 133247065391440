import jsTypeText from 'js-type-text';
export default {
  positionBg(e) {
    const navPos = $('.navbar-brand').offset().left
    const windowWidth = window.innerWidth
    const percentLeftOffset = (navPos / windowWidth) * 100

    const innerContainerHeight = $('.Header').first().height();
    let pinContainer4Left;
    const pinContainerACss = function ($container, navPos, innerContainerHeight) {
      if ($container.hasClass('PinContainer--stance')) {
        return `${navPos - 110}px ${(innerContainerHeight * 0.25 * -1) - 200}px`
      }
      return `${navPos - 110}px ${innerContainerHeight * 0.25 * -1}px`
    }

    $('.Header').css({
      backgroundPosition: pinContainerACss($('.Header'), navPos, innerContainerHeight),
    })


    $('.PinContainer--1').css({
      backgroundPosition: `${percentLeftOffset - 8}vw -750px`
    })

    $('.PinContainer--3').css({
      backgroundPosition: `${percentLeftOffset - 12}vw 0`
    })

    if (windowWidth < 1140) {
      pinContainer4Left = -2700;
    } else {
      pinContainer4Left = navPos - 3000;
    }
    $('.PinContainer--4').css({
      backgroundPosition: `${pinContainer4Left}px 75%`,
    })
  },

  getViewportSize() {
    return $('#sizer').find('div:visible').data('size');
  },
  onScroll() {
    this.fixNav();
    if ($('.Filter__container').length && this.getViewportSize() != "xs") {
      this.fixFilters();
    }
  },

  fixNav(e) {
    const scrollTop = $(window).scrollTop()
    if (scrollTop > 0) {
      $('.navbar').addClass('navbar--is-fixed')
    } else {
      $('.navbar').removeClass('navbar--is-fixed')
    }
  },
  fixFilters(e) {
    const scrollTop = $(window).scrollTop()
    const $filters = $('.Filter__container')
    if (scrollTop > $filters.data().top) {
      this.stickFilter();
    } else {
      this.unstickFilter();
    }
  },

  initCarouselIndex() {
    $('.carousel').each((idx, el) => {
      const $el = $(el);
      if ($el.find('.carousel-controls--with-count')) {
        const totalItems = $el.find('.carousel-item').length;
        $el.find('.carousel-index').html('01/' + ('00' + totalItems).slice(-2));
        $el.off('slide.bs.carousel').on('slide.bs.carousel', function (e) {
          const totalItems = $el.find('.carousel-item').length;
          $el.find('.carousel-index').html(('00' + (e.to + 1)).slice(-2) + '/' + ('00' + totalItems).slice(-2));
        });

      }
    })
  },

  stickFilter() {
    const $filters = $('.Filter__container')
    if ($filters.hasClass('Filter__container--is-fixed')) {
      return
    }
    const $spacer = $('<div class="Filter__spacer"></div>').css({
      height: $filters.outerHeight(),
      opacity: 0
    })

    $filters.before($spacer)
    $filters.addClass('Filter__container--is-fixed')
    $('body').css({
      paddingTop: 0 // $filters.outerHeight()
    })
    $filters.css({
      top: $('.navbar').outerHeight() + 'px',
    })
  },

  unstickFilter() {
    const $filters = $('.Filter__container')
    $('.Filter__spacer').remove();
    $filters.removeClass('Filter__container--is-fixed')
    $('body').css({
      paddingTop: 0
    })
    $filters.css({
      top: 'auto'
    })
  },

  setFilterStickerData() {
    const $filters = $('.Filter__container')
    if ($filters.length) {
      $filters.attr('data-top', $filters.offset().top - $('.navbar').outerHeight());
    }
  },

  positionFlashMessages() {
    const $flashMessages = $('.FlashMessages')
    if ($flashMessages.length) {
      $('.FlashMessages').css({
        top: $('.navbar').outerHeight(),
        display: 'block'
      })
      $flashMessages.one('click', () => {
        $flashMessages.remove();
      })
    }
  },
  setupConsentBanner() {
    function showConsent() {
      const overlay = document.getElementById('overlay');
      if (!overlay) return;

      overlay.classList.remove('hidden');

      const consentAll = document.getElementById('consent-all');
      const declineAll = document.getElementById('decline-all');

      consentAll.addEventListener('click', (event) => {
        event.preventDefault();
        acceptConsent();
      });

      declineAll.addEventListener('click', (event) => {
        event.preventDefault();
        rejectConsent();
      });
    }

    function closeConsent() {
      const overlay = document.getElementById('overlay');
      if (overlay) {
        overlay.classList.add('hidden');
      }
    }

    function acceptConsent() {
      localStorage.setItem('adobeFontsConsent', 'accepted');
      document.body.classList.remove('poppins');

      const link = document.createElement('link');
      link.href = 'https://use.typekit.net/vrb6klw.css';
      link.rel = 'stylesheet';

      const style = document.createElement('style');
      style.textContent = 'body {font-family: "sofia-pro", sans-serif;}';

      document.head.append(link, style);

      closeConsent();
    }

    function rejectConsent() {
      localStorage.setItem('adobeFontsConsent', 'rejected');
      closeConsent();
    }

    const consentStatus = localStorage.getItem('adobeFontsConsent');

    if (consentStatus === 'accepted') {
      acceptConsent();
      return
    };
    if (consentStatus === 'rejected') return;

    showConsent();
  },
  addOrReplaceQueryParam: (key, value)=> {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url);
  },

  removeQueryParam: (key)=> {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState({}, '', url);
  },

  start() {
    const obj = {
      og: $('.PinContainer--4').css('backgroundPositionX')
    }

    this.initCarouselIndex();
    this.setFilterStickerData();
    this.positionFlashMessages();
    this.setupConsentBanner();

    $(window).on('resize', obj, this.positionBg);
    $(window).off('scroll').on('scroll', $.proxy(this.onScroll, this))
    $('.navbar.fixed-top').on('show.bs.collapse', () => {
      $('.navbar.fixed-top').addClass('navbar-collapse--open')
    })
    $('.navbar.fixed-top').on('hide.bs.collapse', () => {
      $('.navbar.fixed-top').removeClass('navbar-collapse--open')
    })

    $(document).on('change', '#gdpr_4981', (e) => {
      const $submit = $('#mc-embedded-subscribe');
      if ($(e.currentTarget).is(':checked')) {
        $submit.removeAttr('disabled');
        $submit.removeClass('disabled');
      } else {
        $submit.attr('disabled', true);
        $submit.addClass('disabled');
      }
    });
    if ($('.CatalogueItems--print').length) {
      window.print();
    }
    $(document).on('change', '.filter-select', function () {
      // submit the form
      var form = document.querySelector('form');
      Rails.fire(form, 'submit');
    });
    $(document).on('click', '.j-toggle-competencies', (e) => {
      e.preventDefault();
      $('.j-competency-row').toggleClass('d-none')
    });

    $(document).on('click', '.j-clickable-img img', function (e) {
      $(this).parents('.j-clickable-img').find('.j-detail-link')[0].click();
    });

    if ($('#carousel').length) {
      const $activeItem = $('.carousel-item.active .heading')
      const $activeItemHtml = $('.carousel-item.active .heading').html()
      jsTypeText.start({
        text: $activeItem.text(),
        speed: 20,
        cursor: false
      }, function (result) {
        $activeItem.html(result);
      });
      window.setTimeout(function () {
        jsTypeText.stop();
        $activeItem.html($activeItemHtml);
        $activeItem.find('a').addClass('animate__bounce animate__animated');
      }, 1500)
    }

    $('.j-placeholder').css('display', 'none');

    this.initCarouselIndex();

    const $autoOpenModalEl = $('#j-auto-open-modal')
    if($autoOpenModalEl.length) {
      const slug = $autoOpenModalEl.attr('data-modal-slug');
      $(`[data-catalogue-item="${slug}"]`).modal('show')
    }

    $(document).on('shown.bs.modal', '[data-catalogue-item]', (e)=> {
      const catalogueItem = $(e.currentTarget).data('catalogue-item');
      if (catalogueItem) {
          this.addOrReplaceQueryParam('planspiel', catalogueItem);
      }
    });

    $('[data-catalogue-item]').on('hidden.bs.modal',  ()=> {
      this.removeQueryParam('planspiel');
      $('#j-auto-open-modal').remove();
    });

    $(document).on('turbolinks:load', () => {
      $(window).trigger('resize');

      this.setFilterStickerData();
      this.positionFlashMessages();

      if ($('main').data('lang') != $('html').attr('lang')) {
        $('html').attr('lang', $('main').data('lang'))
      }


    });

    $('.trix-content img').on('click', function(el) {
        window.open($(el.target).attr('src'), '_blank')
    })

    $('.info-icon').on('click', function(){
        var $overlay = $(this).siblings('.card-overlay');
        $overlay.css({display: 'block'});
        $overlay.on('click', function(){
            $overlay.css({display: 'none'});
            $overlay.off('click');
        })
    })

    $(window).trigger('resize');
    this.fixNav();
  }
}